import {
  CAAS_INDEX_SUFFIX,
  DISABLE_ADS,
  IS_DEV_SITE,
} from "@src/app/constants";
import {
  GA4_REF_CODE_CONTENTBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_C,
  GA4_REF_CODE_CONTENTBLOCK_J,
} from "@src/app/utils/ga/ref-code";
import {
  BRIGHTCOVE_CONFIG,
  DEFAULT_CONFIG_GAM,
  LOTAME_CONFIG,
  PREMUTIVE_CONFIG,
} from "@src/pubs/common/config";

import newsletterbg from "../hw/Home/assets/newsletter-bg-pattern.webp";
import {
  ConfigAboutUs,
  ConfigAffiliatesDeclaration,
  ConfigAmazonAffiliates,
  ConfigAuthorProfile,
  ConfigBrightcove,
  ConfigCaas,
  ConfigCategory,
  ConfigContentHub,
  ConfigError,
  ConfigExploreMore,
  ConfigGAM,
  ConfigGTM,
  ConfigHome,
  ConfigLatestStory,
  ConfigLotame,
  ConfigNewsletter,
  ConfigPermutive,
  ConfigSearch,
  ConfigSingleStory,
  ConfigSite,
  ConfigSkimlinks,
  ConfigTag,
  ConfigTeaser,
  ConfigTrackingCode,
  ConfigVideoLanding,
} from "../types/config";
import HomeContentBlockWOTY from "./Home/HomeContentBlockWOTY";
import { moreIcon } from "./icons/moreIcon";
import logoPath from "./logo.svg?url";

export const configSite: ConfigSite = {
  name: "Her World Singapore",
  address: import.meta.env.VITE_PUBLIC_BASE_URL || "https://www.herworld.com/", // without trailing slash
  tagline: "Career, Money, Wellness, Life & Style",
  description:
    "Her World, Singapore's No. 1 women's magazine, brings you the latest news in career, money, lifestyle, fashion and beauty trends, celebrity news and in-depth women features.",
  titleSeparator: "—",
  logoPath,
  sectionLevel3: true,
  twitterSite: "@herworldsg",
  pageShare: {
    size: 35,
    media: [
      {
        name: "Facebook",
      },
      {
        name: "Whatsapp",
      },
      {
        name: "Email",
      },
      {
        name: "LinkedIn",
      },
      {
        name: "X",
      },
    ],
  },
  aboutUs: {
    headlineBelow: true,
  },
};

export { configHeader } from "./config/configHeader";

export const configCaas: ConfigCaas = {
  index: `herworld${CAAS_INDEX_SUFFIX}`,
  tagIdPrefix: "tag:herworld-keywords@sph.com.sg,2023:",
  cuePublicationName: "herworld",
};

export const configGTM: ConfigGTM = {
  container: "GTM-52VGJMP", //HW
  siteVerification: "_cGEdj2ZMeQhb_OTIAKvlCGbSxaBwct6M6XrDxywcXM",
};

export const configGAM: ConfigGAM = {
  ...DEFAULT_CONFIG_GAM,
  account: "/5908/hw",
  debounce: true,
};

export const configLotame: ConfigLotame = {
  ...LOTAME_CONFIG,
  active: !DISABLE_ADS,
};

export const configPermutive: ConfigPermutive = {
  ...PREMUTIVE_CONFIG,
  active: !DISABLE_ADS,
};

export const configSkimlinks: ConfigSkimlinks = {
  active: !DISABLE_ADS,
  skimlinkId: "157725X1627054",
  geniuslinkId: "319904",
};

export const configBrightcove: ConfigBrightcove = {
  ...BRIGHTCOVE_CONFIG,
};

export const configSingleStory: ConfigSingleStory = {
  authorProfile: true,
  caption: {
    prefix: "Credit: ",
  },
  byline: { defaultCredit: "Her World Team" },
  configShareArticle: {
    label: "Share this article",
    divider: {
      top: false,
    },
  },
  featureStory: {
    skipCoverCaption: true,
    bodyMaxWidth: 830,
  },
  gallery: {
    paginationSeparator: "/",
    galleryItemShareMedia: ["Facebook", "X", "Whatsapp", "Pinterest"],
  },
  displayTags: {
    displayTopTags: false,
    displayTagHeading: true,
  },
};

export const configContentHub: ConfigContentHub = {
  configMasthead: {
    heading: {
      display: false,
    },
  },
  configBodyContent: {
    withHeading: true,
    display: true,
  },
  configShareButton: {
    display: true,
  },
  configEditorsNote: {
    display: false,
  },
  configCallQoute: {
    display: true,
  },
  configFeaturedSection: {
    display: true,
  },
  configDeals: {
    display: true,
    props: {
      heading: "editor's picks",
      noOfItemsInFrame: 4,
    },
  },
  configTopStories: {
    display: true,
    firstItemAnchorBlock: true,
  },
  socialVideo: {
    display: true,
  },
  layout: {
    stories: {
      elements: [
        { name: "contentBlockF", limit: 7 },
        { name: "adsMidContent" },
      ],
    },
  },
};

export const configLatestStory: ConfigLatestStory = {
  latestStoryLimit: 20,
  subAnchorLimit: 3,
  landscapeIndexes: [0, 1, 2],
  mainContentBlockDisplay: "columns",
  mainContentBlockMode: "original",
  errorLayoutMode: ["landscape", "square", "landscape"],
  portraitImageWidth: 285,
  landscapeImageWidth: 345,
};

export const configCategory: ConfigCategory = {
  pinnedTeasersLimit: 3,
  latestStoriesLimit: 8,
  adPositioning: 3,
};

export const configTag: ConfigTag = {
  topStoriesLimit: 4,
  listedStoriesLimit: 12,
};

export const configAmazonAffiliates: ConfigAmazonAffiliates = {
  partnerTag: IS_DEV_SITE ? "sphmedia-dev-22" : "sphmedia-herworld-22",
};

export const configSearch: ConfigSearch = {};

export const configExploreMore: ConfigExploreMore = {
  heading: "Explore more topics",
};

export const configVideoLanding: ConfigVideoLanding = {
  videoPlaylistFillUpSize: 9,
  videoPath: "/video",
};

export const configHome: ConfigHome = {
  headingDivider: false,
  isHeadingClickable: true,
  anchorsWithDescription: ["trending", "videoplaylist"],
  viewMore: { icon: moreIcon },
  latestArticle: {
    skipImage: true,
    landscapeIndexes: [0, 3],
    viewMore: {
      text: "View more",
    },
  },
  contentBlockB: {
    landscapeIndexes: [0, 2],
    itemCenteredIndex: 1,
  },
  contentBlockF: {
    skipSectionContext: true,
    landscapeIndexes: [0, 1, 2],
    layoutTwoColumn: [1, 2],
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
    },
    landscapeImageWidth: 630,
    twoColumnsLandscapeImageWidth: 300,
  },
  contentBlockD: {
    descriptionInTitle: true,
    squareIndexes: [0, 2],
  },
  contentBlockG: {
    skipSectionContext: true,
    landscapeIndexes: [1, 2],
    landscapeImageWidth: 300,
    portraitImageWidth: 300,
  },
  videoGalleryLandscape: {
    hasPagination: false,
    viewMore: {
      href: "/video",
    },
    strip: {
      icon: true,
      dividerVariant: "primary",
    },
    spotLight: {
      mobileLoopingArrowsBelow: true,
    },
  },
  contentColumnSideScroll: {
    section: "shopping",
  },
  affiliateProduct: {
    description: "as curated by the Her World Style team",
  },
  magazineListing: {
    midDivider: true,
  },
  newsletter: {
    containerImageBg: newsletterbg,
    newsletterTitle: `Your life. Your rules. Your style. We’ve got you covered.`,
    newsletterBody: `Sign up for our newsletter for the latest news from Her World.`,
  },
  trending: {
    limit: 4,
  },
  layoutGrid: {
    elements: [
      {
        name: "anchorBlock",
        dataSource: {
          type: "list",
          key: "anchorblock",
          fallback: "latest",
          limit: 1,
        },
      },
      {
        name: "latestArticle",
        dataSource: {
          type: "list",
          key: "pinned",
          fallback: "latest",
          limit: 4,
        },
      },
      {
        Component: HomeContentBlockWOTY,
        dataSource: {
          type: "tag",
          key: "woty",
          min: 2,
          limit: 2,
        },
      },
      {
        name: "contentBlockD",
        dataSource: {
          type: "list",
          key: "trending",
          fallback: "trending",
          refCode: "trending",
          limit: 4,
        },
      },
      {
        name: "adUnit",
      },
      {
        name: "socialVideoPortrait",
        dataSource: { type: "list", key: "socialvideos" },
      },
      {
        name: "affiliateProduct",
        dataSource: {
          type: "list",
          key: "deals",
        },
      },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "independence_career",
          limit: 3,
          refCode: "block-f",
        },
        alternateBg: true,
      },
      {
        name: "columnSideScroll",
        dataSource: {
          type: "section",
          key: "shopping",
          limit: 10,
          refCode: "column-side-scroll",
        },
      },
      {
        name: "contentBlockG",
        dataSource: {
          type: "section",
          key: "independence_money",
          limit: 4,
          refCode: "block-g",
        },
      },
      {
        name: "videoGalleryLandscape",
        dataSource: {
          type: "list",
          key: "videoplaylist",
          refCode: "videoplaylist",
          fallback: "videoplaylist",
          limit: 4,
        },
      },
      {
        name: "contentBlockB",
        dataSource: {
          type: "tag",
          key: "topic-0",
          limit: 3,
          min: 3,
          refCode: "block-b",
        },
        alternateBg: true,
      },
      { name: "magazineListing" },
      { name: "newsletter" },
    ],
  },
  trendingStories: { publisher: "herworld" },
};

export const configNewsletter: ConfigNewsletter = {
  image: newsletterbg,
  title: `Your life. Your rules. Your style. We’ve got you covered.`,
  body: `Sign up for our newsletter for the latest news from Her World.`,
};

export const configTeaser: ConfigTeaser = {};

export const configTrackingCode: ConfigTrackingCode = {
  contenthub: {
    topstories: {
      contentBlockB: GA4_REF_CODE_CONTENTBLOCK_B,
      contentBlockF: GA4_REF_CODE_CONTENTBLOCK_J,
    },
    featured: GA4_REF_CODE_CONTENTBLOCK_C,
  },
};

export const configAffiliatesDeclaration: ConfigAffiliatesDeclaration = {};

export const configError: ConfigError = {};

export const configAuthorProfile: ConfigAuthorProfile = {
  showReadMore: true,
  hideDivider: true,
  listingLayout: {
    mainContentBlockDisplay: "columns",
    mainContentBlockMode: "original",
  },
};

export const configAboutUs: ConfigAboutUs = {
  profileBlock: {
    heading: "meet the team",
  },
};
