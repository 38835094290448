import "@sphtech/dmg-design-system/dist/theme-hw.css";
import "@sphtech/dmg-design-system/assets/hw/fonts.css";

import { configSite } from "@pub/config";
import { BaseLayout, CustomClassContext } from "@sphtech/dmg-design-system";
import Newsticker from "@src/app/components/Modules/Newsticker/Newsticker";
import cx from "classnames";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { configHeader } from "./config/configHeader";
import customStyle from "./custom.module.css";
import logoSvgSource from "./logo.svg?raw";
import { RoutePaths } from "./routePaths";

type HWBaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  skipMobileBanner?: boolean;
};

/**
 * Reusable Base layout specific to HW.
 */
const HWBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  skipMobileBanner,
}: HWBaseLayoutProps) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  return (
    <CustomClassContext.Provider
      value={{
        categoryLayout: {
          sectionContainer: cx(
            location.pathname === "/events" &&
              customStyle["eventsSectionContainer"],
          ),
        },
        navbar: {
          primaryItems: customStyle["primaryItemsContainer"],
        },
        contentHubLayout: {
          userSubmissionBox: customStyle["userSubmission"],
        },
      }}
    >
      <BaseLayout
        ArticleTitle={ArticleTitle}
        ads={ads}
        leaderboard={leaderboard}
        NewsTicker={<Newsticker />}
        RoutePaths={RoutePaths}
        svgSource={logoSvgSource}
        siteName={configSite.name}
        mciText={
          <span suppressHydrationWarning>
            MDDI (P) 044/11/2024. Published by SPH Media Limited, Co. Regn. No.
            202120748H. Copyright © {currentYear} SPH Media Limited. All rights
            reserved.
          </span>
        }
        socialIconProps={configHeader.socialMedia}
        megaMenuProps={{
          NewsLetterProps: configHeader.megaMenu.newsletter,
          CloseButtonProps: configHeader.megaMenu.closeButton,
        }}
        currentLocation={location}
        skipMobileBanner={skipMobileBanner}
      >
        <Helmet
          htmlAttributes={{
            lang: "en-SG",
          }}
        >
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b30010" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        {children}
      </BaseLayout>
    </CustomClassContext.Provider>
  );
};

export default HWBaseLayout;
